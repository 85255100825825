import { DialogService } from 'aurelia-dialog';
import { BacklogDetailsDialog } from './details/backlog-details-dialog';
import { ServiceReportService } from './../../services/service-report-service';
import { ServiceStationService } from 'services/service-station-service';
import { ErrorService } from 'services/error-service';
import { UserService } from 'services/user-service';
import { ServiceFilterModel } from 'models/service-filter-model';
import { autoinject, bindable, observable } from 'aurelia-framework';
import * as moment from 'moment';
import { Router } from 'aurelia-router';
import './service-backlog.scss';

@autoinject
export class ServiceBacklog {
  public serviceStationId: number;
  public requirePlannedDeliveryDate: boolean;
  public ready: boolean;

  private groupedBacklogData: Array<any>;
  private serviceStations: Array<any>;
  private exportServiceBacklogProgress: boolean = false;

  constructor(
    private serviceReportService: ServiceReportService,
    private userService: UserService,
    private serviceStationService: ServiceStationService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private router: Router
  ) {
    this.getServiceStations();
    this.userService.getCurrentUser().then((user) => {
      // build default filters for statistics
      this.serviceStationId = user.ServiceStationId;
      this.requirePlannedDeliveryDate = false;

      this.getBacklog();
    });
  }

  getServiceStations() {
    this.serviceStationService.getAllCached().then((res) => {
      res = res.filter((x) => !x.IsDeleted);
      res.unshift(<any>{
        Id: null,
        Name: '- Vis alle -',
      });

      this.serviceStations = res;
    });
  }

  setServiceStation(serviceStationId) {
    if (serviceStationId === this.serviceStationId) return Promise.resolve();

    this.serviceStationId = serviceStationId;
    return this.updateBacklog();
  }

  requirePlannedDeliveryDateChanged() {
    return this.updateBacklog();
  }

  updateBacklog() {
    return this.getBacklog();
  }

  getBacklog() {
    this.ready = false;
    this.serviceReportService
      .getServiceBacklog(
        this.serviceStationId,
        false,
        this.requirePlannedDeliveryDate
      )
      .then((backlogData) => {
        this.groupedBacklogData = backlogData;
        this.ready = true;
      })
      .catch((err) => {
        this.ready = true;
        this.errorService.handleError(err);
      });
  }

  exportServiceBacklog() {
    this.exportServiceBacklogProgress = true;

    this.serviceReportService
      .getServiceBacklog(
        this.serviceStationId,
        true,
        this.requirePlannedDeliveryDate
      )
      .then((res) => {
        // ignore result, service handles export
        this.exportServiceBacklogProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.exportServiceBacklogProgress = false;
      });
  }

  showDetails(serviceStation, taskCode, type) {
    let params = {
      serviceStationId: serviceStation.ServiceStationId,
      serviceStationName: serviceStation.ServiceStationName,
      taskCode: taskCode,
      isSpecialProduct: type.IsSpecialProduct,
      netTypeId: type.NetTypeId,
      specialProductTypeId: type.SpecialProductTypeId,
      typeName: type.NetTypeName || type.SpecialProductTypeName,
      requirePlannedDeliveryDate: this.requirePlannedDeliveryDate,
    };

    // show dialog with details
    this.dialogService
      .open({
        viewModel: BacklogDetailsDialog,
        model: params,
        lock: false,
        position: (mc, mo) => {},
      })
      .whenClosed((x) => {
        document.querySelector('html').style.overflowY = null;
      })
      .catch((err) => {
        document.querySelector('html').style.overflowY = null;
        this.errorService.handleError(err);
      });
  }
}
