import { ServiceDialog } from './../../../service-dialog/service-dialog';
import { ServiceReportService } from './../../../../services/service-report-service';
import { I18N } from 'aurelia-i18n';
import { ErrorService } from 'services/error-service';
import { autoinject } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { ServiceReportDetail } from 'models/service-report-models';
import { Router } from 'aurelia-router';

interface ServiceFilter {
  serviceStationId: number;
  week: number;
  month: number;
  year: number;
  type: string;
  netTypeId: number;
  specialProductTypeId: number;
}

@autoinject
export class WeekReportDetailsDialog {
  serviceFilter: ServiceFilter;
  services: Array<ServiceReportDetail>;

  constructor(
    private dialogController: DialogController,
    private serviceReportService: ServiceReportService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private router: Router,
    private i18n: I18N
  ) {}

  activate(serviceFilter: ServiceFilter) {
    this.serviceFilter = serviceFilter;
    void this.getDetailsForServices();
  }

  private async getDetailsForServices() {
    try {
      this.services = await this.serviceReportService.getServiceStationWeekReportDetails(
        this.serviceFilter.serviceStationId,
        this.serviceFilter.week,
        this.serviceFilter.month,
        this.serviceFilter.year,
        this.serviceFilter.type,
        this.serviceFilter.netTypeId,
        this.serviceFilter.specialProductTypeId
      );
    } catch (error) {
      this.errorService.handleError(error);
      this.services = [];
    }
  }

  openService(service: ServiceReportDetail) {
    if (!service.ServiceId) {
      const route = !service.IsSpecialProduct
        ? 'net-detail/' + service.NetId
        : 'special-product-detail/' + service.NetId;

      window.open('#/' + route, '_blank');
    } else {
      this.dialogService
        .open({
          viewModel: ServiceDialog,
          model: { Id: service.ServiceId, NetId: service.NetId },
          lock: false,
          position: () => {
            /*Need to be here*/
          },
        })
        .whenClosed(() => {
          document.querySelector('html').style.overflowY = null;
        })
        .catch(() => {
          document.querySelector('html').style.overflowY = null;
        });
    }
  }
}
