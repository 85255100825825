import { ServiceFilterModel } from './../../models/service-filter-model';
import { ServiceService } from 'services/service-service';
import { autoinject, bindable } from 'aurelia-framework';
import * as moment from 'moment';

@autoinject
export class ServiceAntifoulingReport {
    
    private context: string = 'service-antifouling-report';

    private defaultColumns = ['Id','NetId','NetIdentifier','CustomerName','ReceivedFromSiteName','ServiceStationName','ReceivedDate','AntifouledDate','ServiceAntifoulingLiters','ServiceAntifoulingTypeName','ServiceStatusId','ServiceStatusName'];
    
    constructor(private serviceService: ServiceService) {
        let savedFields: Array<string> = JSON.parse(localStorage.getItem(this.serviceService.SELECTED_SERVICE_COLUMNS + '_' + this.context));
      
        if (!savedFields) {
            localStorage.setItem(this.serviceService.SELECTED_SERVICE_COLUMNS + '_' + this.context, JSON.stringify(this.defaultColumns));
        }     
    }
}