import { StatisticsReportDetailsDialog } from './details/statistics-report-details-dialog';
import { DialogService } from 'aurelia-dialog';
import { ServiceReportService } from './../../services/service-report-service';
import { ServiceStationService } from './../../services/service-station-service';
import { ErrorService } from 'services/error-service';
import { UserService } from './../../services/user-service';
import { ServiceFilterModel } from './../../models/service-filter-model';
import { ServiceService } from 'services/service-service';
import { autoinject, bindable, observable } from 'aurelia-framework';
import * as moment from 'moment';
import { Router } from 'aurelia-router';

@autoinject
export class ServiceStatistics {  
    @observable({ changeHandler: 'updateStatistics' }) serviceStationId: number;
    @observable({ changeHandler: 'updateStatistics' }) dateFrom: Date;
    @observable({ changeHandler: 'updateStatistics' }) dateTo: Date;

    private statisticsData: Array<any>;
    private serviceStations: Array<any>;

    private exportReportProgress: boolean = false;

    private hasLoadedData = false;

    constructor(
        private serviceReportService: ServiceReportService,
        private userService: UserService,
        private serviceService: ServiceService,
        private serviceStationService: ServiceStationService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private router: Router
      ) {


        this.getServiceStations();

        let previousParams = sessionStorage.getItem('service-statistics');
        if (previousParams) {
          let params = JSON.parse(previousParams);

          // build default filters for statistics
          this.serviceStationId = !!params.serviceStationId ? +params.serviceStationId : null;
          this.dateFrom = params.from;
          this.dateTo = params.to;        
          
          this.getStatistics();

        } else {        
          this.userService.getCurrentUser()
              .then(user => {            
                  // build default filters for statistics
                  this.serviceStationId = user.ServiceStationId;
                  this.dateFrom = moment.default().add(-1, 'months').toDate();
                  this.dateTo = moment.default().toDate();        
                  
                  this.getStatistics();
              });
      }
    }

    getServiceStations() {
        this.serviceStationService
            .getAllCached()            
            .then(res => {
                res = res.filter(x => !x.IsDeleted)
                res.unshift(<any>{
                    Id: null,
                    Name: '- Vis alle -'
                });

                this.serviceStations = res;
            }); 
    }

    updateStatistics() {
      if (!this.hasLoadedData) {
        return;
      }
      this.getStatistics();

      sessionStorage.setItem('service-statistics', JSON.stringify({from: this.dateFrom, to: this.dateTo, serviceStationId: this.serviceStationId}));
    }

    getStatistics() {
        this.serviceReportService.getServiceStatistics(this.serviceStationId, this.dateFrom, this.dateTo, false)
            .then(res => {
                this.statisticsData = res;
                this.hasLoadedData = true;
            })
            .catch(err => this.errorService.handleError(err));
    }

    private viewStatisticsInServices(row, rowData, task) {

      if (!rowData) {
        return;
      }

      if (task == 'hours') {
        let params = 
        {
            serviceStationId: row.ServiceStationId,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
        };

        // show dialog with details
        this.dialogService.open({ viewModel: StatisticsReportDetailsDialog, model: params, lock: false, position: (mc, mo) => {} })
            .whenClosed(x => {
                document.querySelector('html').style.overflowY = null;        
            }).catch((err) => {
                document.querySelector('html').style.overflowY = null;
                this.errorService.handleError(err);
            });

        return;
      }

      const context = 'statistics';
      const { ServiceStationId, FromDate, ToDate } = row;
      const externalFilters = {};
      const localStorageFiltersKey = `${this.serviceService.SERVICE_FILTERS_KEY}_${context}`;
      const localStorageFiltersKeyExternal = `${this.serviceService.SERVICE_FILTERS_KEY}_${context}_EXTERNAL`;

      const data: any = { ...externalFilters };

      switch (task) {

        case 'received':                    
          data.receivedDate = {
            values: {
              from: FromDate,
              to: ToDate
            },
            visible: true
          };
          data.serviceStationId = ServiceStationId;
          break;

        case 'washed':
          data.washedDate = {
            values: {
              from: FromDate,
              to: ToDate
            },
            visible: true
          };
          data.washingServiceStationId = ServiceStationId;
          break;

        case 'tested':
          data.testedDate = {
            values: {
              from: FromDate,
              to: ToDate
            },
            visible: true
          };          
          data.testingServiceStationId = ServiceStationId;
          break;

        case 'repaired':
          data.repairedDate = {
            values: {
              from: FromDate,
              to: ToDate
            },
            visible: true
          };     
          data.repairServiceStationId = ServiceStationId;
          break;
        
          

        case 'antifouled':
          data.antifouledDate = {
            values: {
              from: FromDate,
              to: ToDate
            },
            visible: true
          };            
          data.antifoulingServiceStationId = ServiceStationId;
          break;
        case 'delivered':
          data.deliveredDate = {
            values: {
              from: FromDate,
              to: ToDate
            },
            visible: true
          };
          data.deliveryServiceStationId = ServiceStationId;
          break;
      }
      
      localStorage.setItem(localStorageFiltersKeyExternal, JSON.stringify(data));
      localStorage.removeItem(localStorageFiltersKey);
      
      this.router.navigateToRoute('service-list', { context });
    }

    exportReport() {
      this.exportReportProgress = true;

      this.serviceReportService.getServiceStatistics(this.serviceStationId, this.dateFrom, this.dateTo, true)
          .then(res => {
              // ignore result, service handles export
              this.exportReportProgress = false;        
          })
          .catch(err => {
              this.errorService.handleError(err);
              this.exportReportProgress = false;
          });
  }
}
