import { ServiceFilterModel } from './../../models/service-filter-model';
import { ServiceService } from 'services/service-service';
import { autoinject, bindable } from 'aurelia-framework';
import * as moment from 'moment';

@autoinject
export class ServiceRepairsReport {    
    private context: string = 'service-repairs-report';

    private defaultColumns = ['Id','NetId','NetIdentifier','CustomerName','ReceivedFromSiteName','ServiceStationName','ServiceStationStorageName','RepairedDate','HoursReparation', 'ServiceStatusId','ServiceStatusName'];

    constructor(private serviceService: ServiceService) {
        let savedFields: Array<string> = JSON.parse(localStorage.getItem(this.serviceService.SELECTED_SERVICE_COLUMNS + '_' + this.context));
      
        if (!savedFields) {
            localStorage.setItem(this.serviceService.SELECTED_SERVICE_COLUMNS + '_' + this.context, JSON.stringify(this.defaultColumns));
        }        
    }
}
