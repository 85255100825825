import { autoinject } from 'aurelia-framework';
import { UpdateSettings } from 'models/Settings';
import { SettingsService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class Revision {
  private settings: UpdateSettings = { Revision: '' };

  constructor(
    private settingsService: SettingsService,
    private errorService: ErrorService,
    private toastService: ToastService
  ) {}

  protected activate() {
    void this.getSettings();
  }

  protected async getSettings() {
    this.settings = await this.settingsService.getSettings();
  }

  async save() {
    try {
      this.toastService.showSuccess('general.saved');
      this.settings = await this.settingsService.update({ Revision: this.settings.Revision });
    } catch (err) {
      this.errorService.handleError(err);
    }
  }
}
