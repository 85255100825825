import { ServiceService } from 'services/service-service';
import { autoinject } from 'aurelia-framework';

@autoinject
export class ServiceInvoicingReport {
  private context: string = 'service-invoicing-report';

  private defaultColumns = [
    'Id',
    'NetId',
    'NetIdentifier',
    'CustomerName',
    'OrderNumber',
    'ReceivedDate',
    'PlannedDeliveryDate',
    'InvoicedWashingStatus',
    'InvoicedTestingStatus',
    'InvoicedRepairStatus',
    'InvoicedAntifoulingStatus',
    'InvoicedSpaghettiStatus',
    'InvoicedDeliveryStatus',
    'ServiceStatusId',
    'ServiceStatusName',
  ];

  protected filtersVisible: Array<string>;

  constructor(private serviceService: ServiceService) {
    const savedFields: Array<string> = JSON.parse(
      localStorage.getItem(this.serviceService.SELECTED_SERVICE_COLUMNS + '_' + this.context)
    );

    if (!savedFields) {
      localStorage.setItem(
        this.serviceService.SELECTED_SERVICE_COLUMNS + '_' + this.context,
        JSON.stringify(this.defaultColumns)
      );
    }
  }
}
