import { MonthReportDetailsDialog } from './details/month-report-details-dialog';
import { DialogService } from 'aurelia-dialog';
import { ServiceReportService } from './../../services/service-report-service';
import { ServiceStationService } from 'services/service-station-service';
import { ErrorService } from 'services/error-service';
import { UserService } from 'services/user-service';
import { ServiceFilterModel } from 'models/service-filter-model';
import { autoinject, bindable, observable } from 'aurelia-framework';
import * as moment from 'moment';
import { Router } from 'aurelia-router';
import tippy from 'tippy.js'

@autoinject
export class ServiceMonthReport {  
    private serviceStationId: number;
    
    private year: number;
    private month: number;

    private exportServiceMonthReportProgress: boolean = false;

    private monthReportData: Array<any>;
    private serviceStations: Array<any>;

    private years: Array<any> = [];
    private months: Array<any> = [];

    private groups = ['Performed', 'Invoiced'] ; 

    constructor(
        private serviceReportService: ServiceReportService,
        private userService: UserService,
        private serviceStationService: ServiceStationService,
        private dialogService: DialogService,
        private errorService: ErrorService,
        private router: Router
      ) {

        this.years.push({Year: moment.default().year() - 2});
        this.years.push({Year: moment.default().year() - 1});
        this.years.push({Year: moment.default().year()});

        for(let i = 0; i < 12; i++) {
            this.months.push({Month: i + 1, Name: moment.default().month(i).format('MMMM')});
        }

        this.getServiceStations();

        let previousParams = sessionStorage.getItem('service-month-report');
        if (previousParams) {
            let params = JSON.parse(previousParams);

            // build default filters for statistics
            this.serviceStationId = !!params.serviceStationId ? +params.serviceStationId : null;
            this.year = params.year;
            this.month = params.month;      
            this.getMonthReport();
        } else {        
            this.userService.getCurrentUser()
                .then(user => {            
                    // build default filters for statistics
                    this.serviceStationId = user.ServiceStationId;
                    this.year = moment.default().year();
                    this.month = moment.default().month() + 1;        
                    
                    this.getMonthReport();
                });
        }
    }

    getServiceStations() {
        this.serviceStationService
            .getAllCached()            
            .then(res => {
                res = res.filter(x => !x.IsDeleted)
                
                res.unshift(<any>{
                    Id: null,
                    Name: '- Vis alle -'
                });

                this.serviceStations = res;
            }); 
    }

    setServiceStation(serviceStationId) {
        if (this.serviceStationId != serviceStationId) {        
            this.serviceStationId = serviceStationId ? serviceStationId : null;
            this.updateMonthReport();
        }
    }

    setYear(year) {
        if (this.year != +year) {
            this.year = +year;
            this.updateMonthReport();
        }
    }

    setMonth(month) {
        if (this.month != +month) {
            this.month = +month;
            this.updateMonthReport();
        }
    }


    updateMonthReport() {
        this.getMonthReport();
        sessionStorage.setItem('service-month-report', JSON.stringify({year: this.year, month: this.month, serviceStationId: this.serviceStationId}));
    }

    getMonthReport() {
        this.serviceReportService.getServiceMonthReport(this.serviceStationId, this.year, this.month, false)
            .then(monthReportData => {
                this.monthReportData = monthReportData;

                setTimeout(() => {          
                    tippy('.tooltip-item', 
                    {
                      allowHTML: true, 
                      maxWidth: '300px',  
                      hideOnClick: true, 
                      content: (reference) => {            
                        const title = reference.getAttribute('title');
                        reference.removeAttribute('title');
                        return title;
                      }
                    });
                  });
            })
            .catch(err => this.errorService.handleError(err));
    }    
    
    showDetails(serviceStation, group, type, netTypeId, specialProductTypeId) {
        let task = group == 'Performed' ? 'Finished' : 'Invoiced';

        let params = 
        {
            serviceStationId: serviceStation.ServiceStationId, 
            year: this.year, 
            month: this.month,
            group: group,
            task: task,
            type: type,
            netTypeId: netTypeId,
            specialProductTypeId: specialProductTypeId
        };

        // show dialog with details
        this.dialogService.open({ viewModel: MonthReportDetailsDialog, model: params, lock: false, position: (mc, mo) => {} })
            .whenClosed(x => {
                document.querySelector('html').style.overflowY = null;        
            }).catch((err) => {
                document.querySelector('html').style.overflowY = null;
                this.errorService.handleError(err);
            });
    }
}
